import { Box, LinkExternal, Logo, Menu, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Link, BrowserRouter as Router } from 'react-router-dom'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import throttle from 'lodash/throttle'

import { useMenuItems } from './hooks/useMenuItems'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import useTheme from 'hooks/useTheme'
import { footerLinks } from './config/footerConfig'
import { languageList, useTranslation } from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { usePhishingBannerManager } from 'state/user/hooks'
import { MENU_HEIGHT, MOBILE_MENU_HEIGHT } from '@pancakeswap/uikit/src/widgets/Menu/config'
import styled from 'styled-components'
import { CurrencyExchange } from 'components/Currency'
import Footer from '../../../packages/uikit/src/components/Footer/index'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import UserMenu from './UserMenu'
import BottomNavV2 from './BottomNavV2'
import UserMenuV2 from './UserMenu/UserMenuV2'
import { NewNav } from './config/configV2'
import images from 'configs/images'
import { getBlockExploreLink } from 'utils'
import { formatEther } from '@ethersproject/units'
import { getContract } from 'utils/contractHelpers'
import { useSigner } from 'wagmi'
import addresses from 'config/constants/contracts'
import poolAbi from 'config/abi/pools.json'
import { ChainId } from '../../../packages/swap-sdk/src/constants'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import Effect from 'components/EffectBackground/index.'
import { Flex } from '@chakra-ui/react'
import axios from 'axios'
import CountUp from 'react-countup'
const BodyWrapper = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  //   min-height: 100vh;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-bottom: 0;
  }
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  flex-grow: 1;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  width: 100%;
`
const Wrapper = styled.div`
  width: 100%;
  background-color: black;
  overflow: hidden;
  font-family: Inter, sans-serif;
`
const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: var(--black-black-60, rgba(0, 0, 0, 0.6));
`
const FixedContainer = styled.div<{ showMenu: boolean; height: number }>`
  position: fixed;
  top: ${({ showMenu, height }) => (showMenu ? 0 : `-${height}px`)};
  padding: 0 20px;
  transition: top 0.2s;
  height: ${({ height }) => `${height + 40}px`};
  width: 100%;
  margin: 0 auto;
  z-index: 20;
  background: #01070d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 120px;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
  @media screen and (max-width: 900px) {
    padding: 0 60px;
  }
  @media screen and (max-width: 575px) {
    padding: 0 30px;
  }
`
const StyledMenuItem = styled.div`
  position: relative;
  justify-content: center;
  color: var(--white-white, #fff);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  max-width: 151px;
  height: 40px;

  &:hover {
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    .dropdown {
      display: flex;
    }
  }
  a:hover {
    color: #fff;
  }
  .dropdown {
    display: none;
  }
  @media screen and (max-width: 1300px) {
    padding: 0 10px;
  }
`
const StyledListItem = styled.div`
  display: flex;
  gap: 16px;
  .active {
    border-radius: var(--border-radius-lg, 8px);
    background: none;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    color: #ff9400;
    font-weight: 700;
  }
  @media screen and (max-width: 1300px) {
    gap: 0;
  }
`
const DropdownMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgb(39, 38, 44);
  border: 1px solid rgb(56, 50, 65);
  width: 180px;
  top: 60px;
  padding: 10px 0;
  border-radius: 20px;
  align-items: flex-start;
  gap: 10px;
`
const StyledDropdownMenu = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  jutify-content: flex-start;
  max-width: 180px;
  &:hover {
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`
const StyledItemNav = styled.div`
  color: var(--white-white, #fff);
  display: flex;
  align-items: center;
  padding: 0px 16px;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  width: 130px;
  height: 40px;

  &:hover {
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`
const NavDropdownMenu = styled.div`
  display: flex;
  max-width: 860px;
  width: 100%;
  gap: 30px;
  margin: 60px auto 0 auto;
  justify-content: center;
  .active {
    border-radius: var(--border-radius-lg, 8px);
    background: rgba(175, 137, 238, 0.2);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
`

const data = [
  {
    img: '',
    link: '/',
    label: 'Home',
    dropdownMenu: [],
  },
  // {
  //   img: '/images/V3/iconPool.svg',
  //   link: '/pools',
  //   label: 'Pools',
  //   dropdownMenu: [
  //     {
  //       img: '/images/V3/iconNew.svg',
  //       link: '/pools',
  //       label: 'Ver2.0',
  //     },
  //     {
  //       img: '',
  //       link: '/pools_V1',
  //       label: 'Ver1.0',
  //     },
  //   ],
  // },
  {
    img: '',
    link: '/referral',
    label: 'Referral',
    dropdownMenu: [],
  },
  // {
  //   img: '/images/V3/iconTokenomic.svg',
  //   link: '/tokenomic',
  //   label: 'Tokenomic',
  //   dropdownMenu: [],
  // },
  {
    img: '',
    link: '/mining-v2',
    label: 'CST2',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/mining',
    label: 'CST',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/leaderboard',
    label: 'LeaderBoard',
    dropdownMenu: [],
  },
  {
    img: '',
    link: '/vote',
    label: 'Vote',
    dropdownMenu: [],
  },
  // {
  //   img: '',
  //   link: '/review',
  //   label: 'Review',
  //   dropdownMenu: [],
  // },
]
const MenuV2 = () => {
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const { account } = useActiveWeb3React()
  const [showMenu, setShowMenu] = useState(true)
  const [classActive, setClassActive] = useState('')
  const [indexActive, setIndexActive] = useState(0)
  // const [indexDropdown , setIndexDropdown] = useState(0)
  // const indexDropdown = useRef(0)
  const [isActive, setisActive] = useState(0)
  const [notFound, setNotFound] = useState(false)
  // console.log(indexDropdown.current);
  const { pathname } = useRouter()
  const { isDark, setTheme } = useTheme()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const banner = showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />
  const { isMobile, isTablet, isTabletOrDesktop } = useMatchBreakpoints()
  const totalTopMenuHeight = banner ? MENU_HEIGHT : MENU_HEIGHT
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const [arrAdmin, setArrAdmin] = useState(data)
  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])
  const subLinks = activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items
  const homeLink = menuItems.find((link) => link.label === 'Home')
  const { search } = window.location
  const query = new URLSearchParams(search)
  const referBy = query.get('ref')
  const refPrevOffset = useRef(typeof window === 'undefined' ? 0 : window.pageYOffset)
  const linkActive = window.location.href
  const local = window.location.origin
  const checkActive = (e, r) => {
    const linkActive = window.location.href
    localStorage.setItem('index', r)
    if (linkActive.indexOf(e) !== -1) {
      setClassActive('active')
      setIndexActive(r)
      setisActive(0)
    } else {
      setClassActive('')
    }
  }

  const checkIsActive = (index) => {
    setisActive(index)
    localStorage.setItem('indexDropdown', index)
  }
  const checkHome = () => {
    if (linkActive.slice(-1) === '/') {
      setClassActive('')
      setIndexActive(0)
      localStorage.setItem('index', '0')
      localStorage.setItem('indexDropdown', '0')
    }
  }
  const checkAdmin = () => {
    const admin = process.env.NEXT_PUBLIC_DEFAULT_ACCOUNT_ADMIN
    var mangBanDau = [...data]
    if (!account) {
      if (linkActive.indexOf('/review') !== -1) {
        mangBanDau.push({
          img: '/images/V3/gift.svg',
          link: '/review',
          label: 'Review',
          dropdownMenu: [],
        })
        setArrAdmin(mangBanDau)
      } else {
        setArrAdmin(data)
        setIndexActive(0)
      }
    } else if (account && admin.includes(account)) {
      mangBanDau.push({
        img: '',
        link: '/review',
        label: 'Review',
        dropdownMenu: [],
      })
      setArrAdmin(mangBanDau)
    } else if (account && !admin.includes(account)) {
      setArrAdmin(data)
    }
  }
  const checkLink = () => {
    for (let i = 1; i < data.length; i + 1) {
      const { link } = data[i]
      if (linkActive.indexOf(link) !== -1) {
        setClassActive('active')
        setIndexActive(i)
        localStorage.setItem('index', i.toString())
      }
      i++
    }
  }
  useEffect(() => {
    checkHome()
    checkLink()
    if (localStorage.getItem('index') !== '0') {
      setIndexActive(Number(localStorage.getItem('index')))
      // indexDropdown.current = Number(localStorage.getItem('indexDropdown'))
      setisActive(Number(localStorage.getItem('indexDropdown')))
      setClassActive('active')
    } else if (localStorage.getItem('index') === '0') {
      setClassActive('')
      setIndexActive(0)
    }
  }, [linkActive, classActive])
  useEffect(() => {
    checkAdmin()
  }, [account, isMobile])
  useEffect(() => {
    const handleScroll = () => {
      const currentOffset = window.pageYOffset
      const isBottomOfPage = window.document.body.clientHeight === currentOffset + window.innerHeight
      const isTopOfPage = currentOffset === 0
      // Always show the menu when user reach the top
      if (isTopOfPage) {
        setShowMenu(true)
      }
      // Avoid triggering anything at the bottom because of layout shift
      else if (!isBottomOfPage) {
        if (currentOffset < refPrevOffset.current || currentOffset <= totalTopMenuHeight) {
          // Has scroll up
          setShowMenu(true)
        } else {
          // Has scroll down
          setShowMenu(false)
        }
      }
      refPrevOffset.current = currentOffset
    }
    const throttledHandleScroll = throttle(handleScroll, 200)

    window.addEventListener('scroll', throttledHandleScroll)
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll)
    }
  }, [totalTopMenuHeight])
  useEffect(() => {
    if (referBy) {
      localStorage.setItem('saveAdd', JSON.stringify(referBy))
    }
  }, [account])
  const [corePrice, setCorePrice] = useState(0)
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { data: signer } = useSigner()
  const [tokenPrice, setTokenPrice] = useState(0)
  const getPoolsContract = getContract({ address: addresses.pools[CHAIN_ID], abi: poolAbi, chainId: CHAIN_ID, signer })
  const getCorePrice = async () => {
    const [price, tokenPrice] = await Promise.all([getPoolsContract.CORE2USDT(), getPoolsContract.CST2USDT()])
    setCorePrice(Number(formatEther(price)))
    setTokenPrice(Number(formatEther(tokenPrice)))
  }
  const [price, setPrice] = useState({
    core: 0,
    coreChange: 0,
    cst: 0,
    cstChange: 0,
  })

  useEffect(() => {
    getPriceTrend()
  }, [])
  const getPriceTrend = async () => {
    const headers = {
      'X-API-Key': '01e54e9712d16936f7a4a333fc6c789f',
      'Content-Type': 'application/json', // (Nếu có)
      // Các headers khác nếu cần
    }
    const baseURL = `https://api.corestake.org/coins/markets`
    const URL = `https://api.corestake.org/token?chain=coredao&address=0x92edf9d66581283e8c7502f288b7be37d25d3722`
    const [core, cst] = await Promise.all([axios.get(baseURL), axios.get(URL)])
    setPrice({
      core: core.data.current_price,
      coreChange: core.data.price_change_percentage_24h,
      cst: cst.data.data.price,
      cstChange: cst.data.data.price24h
        ? ((cst.data.data.price - cst.data.data.price24h.price) * 100) / cst.data.data.price
        : 0,
    })
  }
  useEffect(() => {
    getCorePrice()
  }, [])
  return (
    <Wrapper>
      <Container>
        {/* <Effect /> */}
        <Router>
          <FixedContainer showMenu={showMenu} height={totalTopMenuHeight}>
            <Flex>
              <Flex alignItems="center" gap="20px">
                <div onClick={() => localStorage.setItem('index', '0')}>
                  <Logo isDark={isDark} href={homeLink?.href ?? '/'} />
                </div>
                <Text
                  m={0}
                  style={{
                    borderRadius: '20px',
                    background: 'red',
                    padding: '3px 15px',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  Validators
                </Text>
              </Flex>
              <StyledListItem style={{ display: isMobile || isTablet ? 'none' : 'flex' }}>
                {arrAdmin.map((items, index) => (
                  <div key={index}>
                    <div key={index} style={{ display: index === 0 ? 'none' : 'flex' }}>
                      <StyledMenuItem
                        style={{ display: index === 0 ? 'none' : 'flex' }}
                        className={index === indexActive ? classActive : ''}
                        onClick={() => checkActive(items.link, index)}
                      >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                          {/* <img src={items.img} /> */}
                          <Link to={items.link} style={{ display: index === 0 ? 'none' : 'flex' }}>
                            {items.label}
                          </Link>
                        </div>
                        {items.dropdownMenu.length > 0 ? (
                          <DropdownMenu className="dropdown">
                            {items.dropdownMenu.map((i, k) => (
                              <Link key={i.label} style={{ width: '100%' }} to={i.link}>
                                <StyledDropdownMenu
                                  className={k === isActive ? 'active' : ''}
                                  onClick={() => checkIsActive(k)}
                                >
                                  <Link to={i.link}>{i.label}</Link>
                                  {i.img !== '' ? <img src={i.img} /> : null}
                                </StyledDropdownMenu>
                              </Link>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </StyledMenuItem>
                    </div>
                  </div>
                ))}
              </StyledListItem>
            </Flex>
            <Flex ml="10px" gap="30px" alignItems="center">
              <Flex gap="10px" alignItems="center" display={isMobile || isTablet ? 'none' : 'flex'}>
                <Flex bg="rgba(70, 217, 46, 0.15)" borderRadius="4px" p="8px 12px" gap="10px">
                  <Text
                    color="#fff"
                    fontFamily="Poppins,sans-serif"
                    fontSize={isTablet ? '10px' : '16px'}
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    CORE:
                  </Text>
                  <img src="/images/core/coreIcon.svg" />
                  <Text
                    color="#fff"
                    fontFamily="Poppins,sans-serif"
                    fontSize={isTablet ? '10px' : '16px'}
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    {price.core.toFixed(3)}
                  </Text>
                  {price.coreChange >= 0 ? (
                    <Text style={{ color: '#46D92E', fontWeight: 400 }}>
                      +
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        // end={Number(Number(balance) * rateBnbUsd)}
                        decimals={2}
                        duration={1}
                        end={Number(price.coreChange)}
                      />
                      %
                    </Text>
                  ) : (
                    <Text style={{ color: '#E30721', fontWeight: 400 }}>
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        // end={Number(Number(balance) * rateBnbUsd)}
                        decimals={2}
                        duration={1}
                        end={Number(price.coreChange)}
                      />
                      %
                    </Text>
                  )}
                </Flex>
                <Flex bg="rgba(70, 217, 46, 0.15)" borderRadius="4px" p="8px 12px" gap="10px">
                  <Text
                    color="#fff"
                    fontFamily="Poppins,sans-serif"
                    fontSize={isTablet ? '10px' : '16px'}
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    CST:
                  </Text>
                  <img src="/images/core/cstIcon.svg" />
                  <Text
                    color="#fff"
                    fontFamily="Poppins,sans-serif"
                    fontSize={isTablet ? '10px' : '16px'}
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    {' '}
                    {price.cst.toFixed(5)}
                  </Text>
                  {price.cstChange >= 0 ? (
                    <Text style={{ color: '#46D92E', fontWeight: 400 }}>
                      +
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        // end={Number(Number(balance) * rateBnbUsd)}
                        decimals={2}
                        duration={1}
                        end={Number(price.cstChange)}
                      />
                      %
                    </Text>
                  ) : (
                    <Text style={{ color: '#E30721', fontWeight: 400 }}>
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        // end={Number(Number(balance) * rateBnbUsd)}
                        decimals={2}
                        duration={1}
                        end={Number(price.cstChange)}
                      />
                      %
                    </Text>
                  )}
                </Flex>
              </Flex>
              <Flex alignItems="center" gap="10px" display={isMobile ? 'none' : isTabletOrDesktop ? 'none' : 'flex'}>
                <a href="https://twitter.com/StakeCore" target="_blank" rel="noreferrer">
                  <img src="/images/core/twitter.svg" />
                </a>
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    height: '20px',
                    width: '1px',
                  }}
                />
                <a href="https://t.me/corestakechannel" target="_blank" rel="noreferrer">
                  <img src="/images/core/telegram.svg" />
                </a>
                <div
                  style={{
                    background: 'rgba(255, 255, 255, 0.10)',
                    height: '20px',
                    width: '1px',
                  }}
                />
              </Flex>
            </Flex>
            <UserMenuV2 data={arrAdmin} />
          </FixedContainer>

          {/* {data[indexActive].dropdownMenu.length > 0 && (
            <NavDropdownMenu>
              {data[indexActive].dropdownMenu.map((item, index) => (
                <Link key={index} to={item.link}>
                  <StyledItemNav className={index === isActive ? 'active' : ''} onClick={() => checkIsActive(index)}>
                    <Link to={item.link}>{item.label}</Link>
                    {item.img !== '' ? <img src={item.img} /> : null}
                  </StyledItemNav>
                </Link>
              ))}
            </NavDropdownMenu>
          )} */}

          <NewNav />

          {isMobile && (
            // <BottomNav items={menuItems} activeItem={activeMenuItem?.href} activeSubItem={activeSubMenuItem?.href} />
            <BottomNavV2 data={arrAdmin} />
          )}
          {isTablet && (
            // <BottomNav items={menuItems} activeItem={activeMenuItem?.href} activeSubItem={activeSubMenuItem?.href} />
            <BottomNavV2 data={arrAdmin} />
          )}
        </Router>
      </Container>
      <BodyWrapper mt={!subLinks ? '0' : '0'}>
        <Inner isPushed={false} showMenu={showMenu}>
          <Footer
            items={getFooterLinks}
            isDark={isDark}
            toggleTheme={toggleTheme}
            langs={languageList}
            setLang={setLanguage}
            currentLang={currentLanguage.code}
            cakePriceUsd={Number(cakePriceUsd)}
            buyCakeLabel={t('Buy CAKE')}
            mb={[`${MOBILE_MENU_HEIGHT}px`, null, '0px']}
          />
        </Inner>
      </BodyWrapper>
    </Wrapper>
  )
}

export default MenuV2
